import "../../../../stylesheets/donation-options";
import { validateAmountToDonate } from "../../utils";

$(document).ready(function () {
  // Options selector
  $(".block-option").click(({ target }) => {
    const target_type = target.getAttribute("data-type");
    if (target_type == "options") {
      const amount = target.getAttribute("data-amount");
      const currency = target.getAttribute("data-currency");
      const block_id = target.getAttribute("data-block-id");
      $('.currency-selector-button[data-currency="' + currency + '"]').trigger(
        "click"
      );
      $("#donator_donation_transactions_attributes_0_amount").val(amount);
      validateAmountToDonate("once", currency);
      $(`div.block-option`).removeClass("active-option");
      $(`div.block-option[data-block-id="${block_id}"]`).toggleClass(
        "active-option"
      );
    }
  });
});
